import {Link, Typography} from "@mui/material";
import React from 'react';
import {DOBFields, NameFields} from "../pages/my-account/profile/textFields";
import {graphqlUserInfoToContext} from '../util/graphql';
import {FRUser, HttpClient} from '@forgerock/javascript-sdk';
import {GraphQLResponse} from '../util/types/utilTypes';
import {Checkboxes, MyAccountType, ProfileInfoType, SingleAddress} from '../contexts/types/myAccount';
import {EmailInput} from '../pages/my-account/change-email/changeEmailTypes';
import {PasswordInfo} from '../pages/my-account/change-password/changePasswordTypes';
import {PhoneNumbers} from '../pages/my-account/profile/profile';
import {AM_URL, LINK_CONTACT_US, LINK_CONTACT_US_CHOICES, MICROSERVICE_URL} from '../util/constants';
import {removeSpacePostalCode} from "../pages/my-account/addresses/helpers/postalCodeFormatter";
import {LoginStateType} from "../pages/login/loginTypes";

export type GoogleGraphQLData = {
  success?: boolean,
  challenge_ts?: string,
  hostname?: string,
  score?: number,
  action?: string,
  message?: string
}

export type GoogleGraphQLV2Data = {
  success?: boolean,
  challenge_ts?: string,
  hostname?: string,
  message?: string
}

export type NameFieldsToAPI = {
  firstName: string,
  lastName: string,
  dateOfBirth: string
}

export const handleServiceUnavailable = async (
  res: Response
): Promise<Response> => {
  if (res.status === 503) {
    window.location.replace('/maintenance');
  } else if (res.status === 502) {
    window.location.replace('/err');
  }
  return res;
}

export const getMessage = (): Promise<any> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/message`,
    init: {
      method: 'GET'
    }
  }).then((r: Response) => r);
}

export const updateUsersEmailSubscriptionsUnsub = (
  checkboxesState: Checkboxes,
  id: string
) => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/change-email-subscriptions-unsub`,
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        checkboxesState,
        id
      })
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json());
}

// Updates users email subscriptions from microservice
export const updateUsersEmailSubscriptions = (
  checkboxesState: Checkboxes
): Promise<GraphQLResponse> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/change-email-subscriptions`,
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        checkboxesState
      })
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json());
};

export const deleteUserAddress = (
  singleAddressDialogue: SingleAddress,
  enqueueSnackbar: any,
  theme: string
) => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/delete-address`,
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        singleAddressDialogue,
        theme
      })
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json())
    .catch((e) => {
        return enqueueSnackbar(
          <>
            <Typography
              sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
              An unexpected error occurred when trying to delete your address. Please try again. If this issue persists
              please&nbsp;
              <Link
                href={theme === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US}
                target="_blank"
                className="link-white">contact us
              </Link>
            </Typography>
          </>,
          { variant: 'error' }
        );
      }
    );
}

export const createUserAddress = (
  singleAddressDialogue: SingleAddress,
  selectedProvince: string,
  setSavingChange: React.Dispatch<React.SetStateAction<boolean>>,
  enqueueSnackbar: any,
  theme: string
) => {
  // remove blanks from address fields
  singleAddressDialogue.cityName = singleAddressDialogue.cityName.trim();
  singleAddressDialogue.street1 = singleAddressDialogue.street1.trim();
  singleAddressDialogue.street2 = (singleAddressDialogue.street2 !== null) ? singleAddressDialogue.street2.trim() : null;
  singleAddressDialogue.postalCode = (singleAddressDialogue.postalCode !== null) ? removeSpacePostalCode(singleAddressDialogue.postalCode) : null;
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/create-address`,
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        singleAddressDialogue,
        selectedProvince,
        theme
      })
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json())
    .catch((e) => {
        setSavingChange(false);
        return enqueueSnackbar(
          <>
            <Typography
              sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
              An unexpected error occurred when trying to create your address. Please try again. If this issue persists
              please&nbsp;
              <Link
                href={theme === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US}
                target="_blank"
                className="link-white"
              >contact us
              </Link>
            </Typography>
          </>,
          { variant: 'error' }
        );
      }
    );
}

// Updates users address from microservice
export const updateUsersAddress = (
  singleAddressDialogue: SingleAddress,
  selectedProvince: string,
  setSavingChange: React.Dispatch<React.SetStateAction<boolean>> | null,
  enqueueSnackbar: any,
  theme: string
): Promise<string> => {
  // remove blanks from address fields
  singleAddressDialogue.cityName = singleAddressDialogue.cityName.trim();
  singleAddressDialogue.street1 = singleAddressDialogue.street1.trim();
  singleAddressDialogue.street2 = (singleAddressDialogue.street2 !== null) ? singleAddressDialogue.street2.trim() : null;
  singleAddressDialogue.postalCode = (singleAddressDialogue.postalCode !== null) ? removeSpacePostalCode(singleAddressDialogue.postalCode) : null;
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/change-address`,
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        singleAddressDialogue,
        selectedProvince,
        theme
      })
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json())
    .catch((e) => {
        enqueueSnackbar(
          <>
            <Typography
              sx={{ marginRight: '5px', marginLeft: '5px', cursor: 'pointer' }}>
              An unexpected error occurred when trying to edit your address. Please try again. If this issue persists
              please&nbsp;
              <Link
                href={theme === 'choices' ? LINK_CONTACT_US_CHOICES : LINK_CONTACT_US}
                target="_blank"
                className="link-white"
              >contact us
              </Link>
            </Typography>
          </>,
          { variant: 'error' }
        );
        if (setSavingChange) setSavingChange(false);
      }
    );
};

export const updateUsersPhoneOnly = (
  phoneEditState: PhoneNumbers,
  profileInfo: ProfileInfoType,
  theme: string
): Promise<any> => {
  const email: string = profileInfo.email;
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/change-customers-phone`,
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ phoneEditState, email, theme })
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json());
};

// Updates users phone number from microservice
export const updateUsersProfile = (
  phoneEditState: PhoneNumbers,
  profileInfo: ProfileInfoType,
  nameFields: NameFields,
  theme: string,
  nameHasChanged: boolean,
  dobFields: DOBFields
): Promise<any> => {
  const email: string = profileInfo.email;
  const newNames: NameFieldsToAPI = {
    firstName: nameFields.firstName,
    lastName: nameFields.lastName,
    dateOfBirth: profileInfo.dobString
  }
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/change-customers-profile`,
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ phoneEditState, email, newNames, theme, nameHasChanged, dobFields })
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json());
};

// Gets the user information from the microservice
export const getRawUserInfo = (
  setError: React.Dispatch<React.SetStateAction<string>>
): Promise<void | GraphQLResponse> => {
  return HttpClient.request({
    timeout: 10000,
    url: `${MICROSERVICE_URL}/customer/info`,
    init: {
      method: 'GET'
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then(async (r: Response) => {
    if (r.status === 404) {
      await FRUser.logout();
      window.location.replace('/');
    } else {
      return r.json();
    }
  })
    .catch((e) => {
        return setError(`Error loading, please try again. 
        If the issue persists, please contact us for help.`);
      }
    );
};

export const completeEmailChangeRequest = (
  jwt: string
): Promise<any> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/complete-email-change`,
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ jwt })
    }
  });
};

// Updates the users email address from the microservice
export const sendChangeCustomerEmail = (
  email: EmailInput,
  theme: string
): Promise<any> => {
  const newEmail: string = email.newEmail;
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/edit-email`,
    init: {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ newEmail, theme: theme })
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json());
};

// Send a request to delete account
export const sendAccountDeletionRequest = (
  email: string
): Promise<string> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/delete-account`,
    init: {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => {
    if (r.status === 200) {
      return 'success';
    }
  })
    .catch((error) => {
        console.error('Error: ', error);
        return 'error';
      }
    );
};

// Updates a users password from the microservice
export const updateUsersPassword = (
  passwordInfo: PasswordInfo,
  theme: string
): Promise<Response> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/edit-password`,
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        oldPassword: passwordInfo.oldPassword.input,
        newPassword: passwordInfo.newPassword.input,
        theme: theme
      })
    }
  }).then((r: Response) => handleServiceUnavailable(r));
};

// Gets the user information from the microservice
export const getRecaptchaScoreEndpoint = (
  token: string,
  enqueueSnackbar: any
): Promise<GoogleGraphQLData> => {
  return fetch(`${MICROSERVICE_URL}/recaptcha/verify/?recapchaVerify=${token}`, {
    method: 'GET'
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json())
    .catch((error) => {
        console.error('Error: ', error);
        enqueueSnackbar(
          <>
            <Typography
              sx={{
                marginRight: '5px',
                marginLeft: '5px',
                cursor: 'pointer'
              }}
              onClick={() => {
                // @ts-ignore
                window.open(window.open(LINK_CONTACT_US));
              }}>
              An unexpected error occurred and we are not able to sign you in right now,
              please try again or&nbsp;<Link
              href={LINK_CONTACT_US}
              target="_blank"
              className="link-white"
            >contact us</Link>&nbsp;
            </Typography>
          </>,
          { variant: 'error' }
        );
      }
    );
};

// Gets the user information from the microservice
export const getRecaptchaV2Endpoint = (token: string): Promise<GoogleGraphQLV2Data> => {
  return fetch(`${MICROSERVICE_URL}/recaptcha/verify-v2/?recapchaVerify=${token}`, {
    method: 'GET'
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json());
};

// Parses user information from microservice and returns as MyAccountType
export const getContextUserInfo = async (
  setError: React.Dispatch<React.SetStateAction<string>>
): Promise<MyAccountType | void> => {
  try {
    const result: GraphQLResponse | void = await getRawUserInfo(setError);
    if (result && result.message !== 'not found') {
      return graphqlUserInfoToContext(result);
    }
    return setError(`Error loading, please try again.`);
  } catch (e) {
    return setError(`Error loading, please try again.`);
  }
};

// Get Recaptcha Score
export const getRecaptchaScore = async (
  token: string,
  loginFlowRecaptchaFailure: boolean,
  enqueueSnackbar: any,
  setLoginState: React.Dispatch<React.SetStateAction<LoginStateType>>,
): Promise<void> => {
  const response: GoogleGraphQLData = await getRecaptchaScoreEndpoint(token, enqueueSnackbar);
  if (response && response.message === 'not found') {
    setLoginState((prev: LoginStateType) => ({ ...prev, loading: false, }));
    return enqueueSnackbar(`Something doesn't seem right with your sign in attempt, please try again. If the issue persists please <a href='https://www.morerewards.ca/contact-us' target='_blank' class='link-dark'>contact us</a>`, { variant: 'error' });
  }
  if (response) {
    setLoginState((prev: LoginStateType) => ({
      ...prev,
      recaptchaScore: {
        ...prev.recaptchaScore,
        success: response.success,
        challenge_ts: response.challenge_ts,
        hostname: response.hostname,
        score: response.score,
        action: response.action
      }
    }))
  }
  if (loginFlowRecaptchaFailure) {
    console.error(loginFlowRecaptchaFailure);
    return setLoginState((prev: LoginStateType) => ({
      ...prev,
      loading: false,
      recaptchaFlowFailedOnMount: true
    }));
  }
};

export const getRecaptchaV2 = async (
  token: string
): Promise<GoogleGraphQLV2Data> => {
  return await getRecaptchaV2Endpoint(token);
};

export const appleWallet = (mrNumber: string): Promise<Response> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/apple-wallet`,
    init: {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        moreRewardsNumber: mrNumber
      })
    }
  }).then((r: Response) => handleServiceUnavailable(r));
};

export const googlePay = (mrNumber: string): Promise<Response> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/google-pay`,
    init: {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        moreRewardsNumber: mrNumber
      })
    }
  }).then((r: Response) => handleServiceUnavailable(r));
};

export const appleWalletWithJwt = (mrNumber: string, jwt: string): Promise<Response> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/register/apple-wallet`,
    init: {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        moreRewardsNumber: mrNumber,
        jwt: jwt
      })
    }
  }).then((r: Response) => handleServiceUnavailable(r));
};

export const googlePayWithJwt = (mrNumber: string, jwt: string): Promise<Response> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/register/google-pay`,
    init: {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        moreRewardsNumber: mrNumber,
        jwt: jwt
      })
    }
  }).then((r: Response) => handleServiceUnavailable(r));
};

export const getUsersUnsubOptions = (
  id: string
): Promise<any> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/get-email-subscriptions`,
    init: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id })
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json());
};

// https://stackoverflow.com/questions/34558264/fetch-api-with-cookie
export const validateToken = async (): Promise<any> => {
  const myHeaders = new Headers();
  myHeaders.append('Accept-API-Version', 'resource=4.0');
  const url = `${AM_URL}/json/morerewards/sessions/?_action=validate&refresh=false`;
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include'
  };
  // @ts-ignore
  return fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => {
      return result;
    })
    .catch(error => {
      return error;
    });
};

export const verifyMergedAccount = async (): Promise<any> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/verify-merged-account`,
    init: {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json());
};

export const deregisterDevice = async (enqueueSnackbar: any): Promise<any> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/deregister-device`,
    init: {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  }).then((r: Response) => {
    if (r.status !== 200) {
      enqueueSnackbar("An unexpected error occurred. Please try again.", { variant: 'error' });
      return r;
    } else {
      handleServiceUnavailable(r)
      return r;
    }
  });
};

export const sendACEmail = async (): Promise<any> => {
  return HttpClient.request({
    timeout: 0,
    url: `${MICROSERVICE_URL}/customer/email-activation-code`,
    init: {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
  }).then((r: Response) => handleServiceUnavailable(r)).then((r: Response) => r.json())
    .catch(error =>  console.error(error));
}