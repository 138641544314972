import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {MoreRewardsCardProps} from '../myAccountTypes';
import pushRouteChangeEvent, {pushSpecificEvent} from '../../../util/google-tag-manager';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {Button, CircularProgress, Link} from '@mui/material';
import {useAtom} from 'jotai';
import {profileInfoAtom} from '../../../jotai-state-management/profile-global-state';
import MoreRewardsCardDisplay from '../../../components/card-display/mr-card-display';
import {appleWallet, googlePay} from '../../../network-requests/resources';
import {LINK_CONTACT_US} from "../../../util/constants";
import { handleACRequest } from "../profile/helpers/helpers";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import {useSnackbar} from 'notistack';

export default function MoreRewardsCard(props: MoreRewardsCardProps) {
  const navigate = useNavigate();
  const { loading, theme, setSelectedTab } = props;
  const [profileInfo] = useAtom(profileInfoAtom);
  const [displayGooglePay] = useState(navigator.userAgent.indexOf('CriOS') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1);
  const [displayApplePay] = useState(!(navigator.userAgent.indexOf('CriOS') !== -1 || navigator.userAgent.indexOf('Chrome') !== -1) && navigator.userAgent.indexOf('Safari') !== -1);
  const [sendingACNumber, setsendingACNumber] = useState<boolean>(false);
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (theme === 'choices') {
      setSelectedTab(0);
      navigate('/userPage/home');
    }
    pushRouteChangeEvent('Profile: MoreRewardsCard');
  }, []);

  const handleGooglePayClick = async () => {
    try {
      let wallet: Response = await googlePay(profileInfo.moreRewardsCard);
      if (wallet.status != 200) {
        console.error('Error in generating google pay', wallet.text());
        return;
      }
      let jwt: string = await wallet.json();
      window.open(`https://pay.google.com/gp/v/save/${jwt}`, '_blank');
      pushSpecificEvent('Add to Google wallet');
    } catch (e) {
      console.error('Error Downloading Google Wallet', e);
    }
  };

  const appleWalletDownload = async () => {
    try {
      let wallet: Response = await appleWallet(profileInfo.moreRewardsCard);
      if (wallet.status !== 200) {
        console.error('Error in downloading apple wallet', wallet.text());
        return;
      }
      let walletJson: any = await wallet.json();
      let element: HTMLAnchorElement = document.createElement('a');
      element.setAttribute(
        'href',
        'data:application/vnd.apple.pkpass;base64,' +
        encodeURIComponent(walletJson.appleFile)
      );
      element.setAttribute('download', 'apple-wallet.pkpass');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      pushSpecificEvent('Add to Apple wallet');
    } catch (e) {
      console.error('Error Downloading Apple Wallet', e);
    }
  };

  return (
    <div>
      <Box>
        <Paper elevation={0}>
          {loading
            ?
            <div className="loadingProfile">
              <Box>
                <CircularProgress className="circularProgressSpinner"/>
              </Box>
            </div>
            :
            <>
              <div className="infoBar infoBarMobile">
                <div className="justifyContent">
                  <div className="info">
                    <h3>Card Number</h3>
                    <span className="largerText fontweightNormal">{profileInfo.moreRewardsCard}</span>
                  </div>
                  {profileInfo.points !== 'There was an error loading your points balance, please refresh the page. ' +
                  'If this issue persists please <a href={LINK_CONTACT_US} target=\'_blank\'>contact us</a>.'
                    ?
                    <div className="info"><h3>You have</h3>
                      <span className="largerText">
                        {profileInfo.points} {
                        profileInfo.points ||
                        // @ts-ignore
                        profileInfo.points === 0 ? 'pts' : ''
                      }
                      </span>
                    </div>
                    :
                    <div className="info"><span className="smallerText">{profileInfo.points}</span></div>
                  }
                </div>
                <div>
                  <p>
                    Your AC# can be found on the back of your card, on your Google or Apple wallets, or sent to your
                    email address by clicking "Email AC#".&nbsp;
                    <Link
                      href={LINK_CONTACT_US}
                      target="_blank"
                      className="link-tamewhite"
                    >
                      Contact us
                    </Link>&nbsp;with any questions.
                  </p>
                </div>
                <div className="phoneButton">
                  {sendingACNumber
                    ?
                    <LoadingButton
                      loading
                      className="primaryButtonDefault"
                      loadingPosition="start"
                      sx={{mt: 3, mb: 2}}
                      startIcon={<SaveIcon/>}
                      variant="outlined"
                    >
                      Sending...
                    </LoadingButton>
                    :
                    <>
                      <Button
                        // @ts-ignore
                        type="submit"
                        className="primaryButtonDefault"
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        onClick={() => handleACRequest(
                          setsendingACNumber,
                          enqueueSnackbar
                        )}
                      >
                        Email AC#
                      </Button>
                    </>
                  }
                </div>
              </div>
              <div className="moreRewardsCard">
                <MoreRewardsCardDisplay card={profileInfo.moreRewardsCard}/>
              </div>
              <div className="centeredContainer">
                <div className="walletButtons">
                  {displayApplePay &&
                    <a onClick={appleWalletDownload} className="addToPayButton">
                      <img
                        src={require('../../../images/apple-wallet-button-two-line-large_2x.png')}
                        alt="Add to Apple Wallet" height={55}
                      />
                    </a>}
                  {displayGooglePay &&
                    <a onClick={handleGooglePayClick} className="addToPayButton">
                      <img
                        className="addToPayImage"
                        src={require('../../../images/enCA_add_to_google_wallet_add-wallet-badge.png')}
                        alt="Add to Google Wallet"
                      />
                    </a>
                  }
                </div>
              </div>
            </>
          }
        </Paper>
      </Box>
    </div>
  );
}